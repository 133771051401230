import { DividerWithText as BaseDividerWithText } from '@mindpal-co/mindpal-ui';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import classNames from 'classnames';
import type { ComponentProps, FC } from 'react';

import Text from './Text';

const dividerStyles = cva('', {
  variants: {
    variant: {
      primary:
        'text-neutral-400 before:border-neutral-400 after:border-neutral-400',
      primaryDark:
        'text-neutral-600 before:border-neutral-600 after:border-neutral-600',
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
});

type Props = ComponentProps<typeof BaseDividerWithText> & {
  textClassName?: React.ComponentProps<'span'>['className'];
} & VariantProps<typeof dividerStyles>;

const DividerWithText: FC<Props> = ({
  textClassName,
  children,
  className,
  variant,
}) => {
  return (
    <BaseDividerWithText
      className={classNames(dividerStyles({ variant }), className)}
    >
      <Text as="span" className={classNames('inline-block', textClassName)}>
        {children}
      </Text>
    </BaseDividerWithText>
  );
};

export default DividerWithText;
