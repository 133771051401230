import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { boolean, object } from 'yup';

import { queries } from '@/queries';
import { postFeedback } from '@/services/cvGenerator';

import Button from '../Button';
import DividerWithText from '../DividerWithText';
import Text from '../Text';
import { Dialog, DialogContent, DialogHeader } from '../ui/dialog';
import { Toggle } from '../ui/toggle';

const fields = [
  { label: 'Title', name: 'nameFieldWasGreat' },
  { label: 'Job title', name: 'jobTitleFieldWasGreat' },
  { label: 'Location', name: 'locationFieldWasGreat' },
  { label: 'Summary', name: 'summaryFieldWasGreat' },
  { label: 'Soft skills', name: 'softSkillsFieldWasGreat' },
  { label: 'Hard skills', name: 'hardSkillsFieldWasGreat' },
  { label: 'Languages', name: 'languagesFieldWasGreat' },
  { label: 'Education', name: 'educationFieldWasGreat' },
  { label: 'Work experience', name: 'workExperienceFieldWasGreat' },
  {
    label: 'Years of experience',
    name: 'yearsOfWorkingExperienceFieldWasGreat',
  },
];

type Props = {
  id: number;
  open?: boolean;
  onOpenChange?: (value: boolean) => void;
};

const defaultValues = Object.fromEntries([
  ...fields.map(({ name }) => [name, false]),
  ['nothingNoteworthy', true],
]);

const FeedbackDialog: FC<Props> = ({ id, open, onOpenChange }) => {
  const queryClient = useQueryClient();
  const { control, watch, setValue, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...defaultValues,
    },
  });
  const { mutate, isLoading } = useMutation({
    mutationKey: ['contextFeedback', id],
    mutationFn: postFeedback,
    onSuccess: () => {
      onOpenChange?.(false);
      toast.success('Thank you for submitting your feedback!');
      queryClient.invalidateQueries({
        queryKey: queries.cvGenerator.detail(id).queryKey,
      });
    },
  });
  const nothingNoteworthyValue = watch('nothingNoteworthy');
  return (
    <Dialog
      open={open}
      onOpenChange={(newOpenValue) => {
        if (!newOpenValue) mutate({ id, data: defaultValues });
        onOpenChange?.(newOpenValue);
      }}
    >
      <DialogContent>
        <DialogHeader>Feedback</DialogHeader>
        <Text className="text-neutral-800">
          Is any part of the resume particularly good?
          <br />
          <Text as="span" variant="body-small">
            (answers are used to improve future exprience)
          </Text>
        </Text>
        <form
          className="flex flex-col gap-2"
          onSubmit={handleSubmit((data) => mutate({ id, data }))}
        >
          {fields.map(({ label, name }) => (
            <div key={name} className="flex items-center justify-center gap-3">
              <div className="flex-1 rounded-lg border border-neutral-800 px-5 py-2">
                <Text className="text-neutral-800">{label}</Text>
              </div>
              <div className="flex gap-3">
                <Controller
                  control={control}
                  name={name}
                  render={({ field: { onChange, value } }) => (
                    <Toggle
                      pressed={value && !nothingNoteworthyValue}
                      onPressedChange={(pressed) => {
                        setValue('nothingNoteworthy', false);
                        onChange(pressed);
                      }}
                      className="hover:bg-successDark/30 data-[state=on]:bg-successDark/30"
                    >
                      <FontAwesomeIcon
                        icon={faThumbsUp}
                        className="text-neutral-800"
                      />
                    </Toggle>
                  )}
                />
              </div>
            </div>
          ))}
          <DividerWithText className="text-neutral-1000">or</DividerWithText>
          <Controller
            control={control}
            name="nothingNoteworthy"
            render={({ field: { onChange, value } }) => {
              return (
                <Toggle
                  className="hover:bg-neutral-300 data-[state=on]:bg-neutral-300"
                  onPressedChange={onChange}
                  pressed={value}
                >
                  <Text className="text-neutral-800">Nothing noteworthy</Text>
                </Toggle>
              );
            }}
          />
          <Button type="submit" isLoading={isLoading} variant="tertiary">
            Submit
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default FeedbackDialog;

const getObjectShapeFromArray = (arr: string[]) =>
  arr.reduce(
    (schema, key) => ({
      ...schema,
      [key]: boolean().required(),
    }),
    {}
  );

export const requiredFieldsSettingsSchema = object()
  .shape(
    getObjectShapeFromArray([
      ...fields.map(({ name }) => name),
      'nothingNoteworthy',
    ])
  )
  .required();

const schema = requiredFieldsSettingsSchema;
