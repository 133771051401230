import { AxiosError } from 'axios';
import { useRouter } from 'next/router';
import type { FC } from 'react';

import Button from './Button';
import Text from './Text';

type Props = {
  error?: unknown;
  errorText?: string;
  buttonText?: string;
  onClick?: () => void;
  onRefetch: () => void;
};

const NetworkFetchError: FC<Props> = ({
  error,
  errorText: defaultErrorText,
  onClick: defaultOnClick,
  buttonText: defaultButtonText,
  onRefetch,
}) => {
  const router = useRouter();
  const defaultError = {
    errorText: defaultErrorText ?? 'Something went wrong',
    onClick: defaultOnClick ?? onRefetch,
    buttonText: defaultButtonText ?? 'Try again',
  };

  const getError = () => {
    if (error && error instanceof AxiosError) {
      const err =
        {
          401: {
            errorText: 'You need to be logged in to access this page',
            onClick: () => router.push('/dashboard/auth/signin'),
            buttonText: 'Sign in',
          },
          403: {
            errorText:
              "You don't have required permissions to access this page",
            onClick: () => router.push('/dashboard/auth/signin'),
            buttonText: 'Sign in',
          },
        }[error.response?.status ?? 0] ?? defaultError;

      return {
        errorText: defaultErrorText ?? err.errorText,
        onClick: defaultOnClick ?? err.onClick,
        buttonText: defaultButtonText ?? err.buttonText,
      };
    }
    return defaultError;
  };

  const { errorText, onClick, buttonText } = getError();
  return (
    <div className="flex flex-1 items-center justify-center">
      <div className="flex max-w-md flex-1 flex-col items-start justify-center gap-3">
        <Text variant="body-small" className="text-error">
          Error
        </Text>
        <Text variant="h4">{errorText}</Text>
        <Button className="self-stretch" variant="secondary" onClick={onClick}>
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

export default NetworkFetchError;
