import { useQuery } from '@tanstack/react-query';

import type { UseQueryFactoryProps } from '@/queries';
import { queries } from '@/queries';

export const useExternalCandidatesCollections = (
  props?: UseQueryFactoryProps<typeof queries.externalCandidates.collections>
) =>
  useQuery({
    ...queries.externalCandidates.collections,
    staleTime: Infinity,
    ...props,
  });
