/* eslint-disable react/jsx-no-useless-fragment */
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type FC, type PropsWithChildren, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import type { InferType } from 'yup';
import { object, string } from 'yup';

import { LocalStorage } from '@/config/localStorage';
import { useProfile } from '@/hooks/useProfile';
import { queries } from '@/queries';
import { editProfile } from '@/services/profile';

import Button from './Button';
import PhoneInputField from './Form/Fields/PhoneInputField';
import TextInputField from './Form/Fields/TextInputField';
import Header from './Header/Header';
import LoadingPlaceholder from './LoadingPlaceholder';
import NetworkFetchError from './NetworkFetchError';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
} from './ui/dialog';

const BlockPageWithoutRequiredData: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const queryClient = useQueryClient();
  const [lastTimeShown, setLastTimeShown] = useState<number | null>(null);
  useEffect(() => {
    setLastTimeShown(
      parseInt(
        localStorage.getItem(
          LocalStorage.LAST_TIME_SHOWN_UNCOMPLETED_FIELDS_DIALOG
        ) ?? '0',
        10
      )
    );
  }, []);

  const { isLoading, data, error, refetch } = useProfile();
  const requiredConditions = {
    phone: !data?.phone || data.phone.length <= 0,
    linkedinUrl: !data?.linkedinUrl || data.linkedinUrl.length <= 0,
  };
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<InferType<typeof schema>>({
    defaultValues: { phone: '' },
    resolver: yupResolver(schema),
    context: requiredConditions,
  });
  const { mutate } = useMutation({
    mutationFn: editProfile,
    mutationKey: ['editProfile'],
    onSuccess: (newProfileData) => {
      queryClient.setQueryData(
        queries.profile.me.queryKey,
        () => newProfileData
      );
      queryClient.invalidateQueries({
        queryKey: queries.profile.me.queryKey,
      });
    },
  });

  const handleSaveTime = (time: number) => {
    localStorage.setItem(
      LocalStorage.LAST_TIME_SHOWN_UNCOMPLETED_FIELDS_DIALOG,
      time.toString()
    );
    setLastTimeShown(time);
  };

  const allowableTimeWithoutOpenDialog = 24 * 60 * 60 * 1000;

  const isUncompletedFields =
    Object.values(requiredConditions).some((condition) => condition) &&
    (lastTimeShown ?? 0) < Date.now() - allowableTimeWithoutOpenDialog;

  if (error) return <NetworkFetchError error={error} onRefetch={refetch} />;
  if (isLoading) return <LoadingPlaceholder />;
  if (process.env.NODE_ENV === 'development') return <>{children}</>;
  if (isUncompletedFields)
    return (
      <div className="flex flex-1 flex-col">
        <Header />
        <LoadingPlaceholder />
        <Dialog open onOpenChange={() => handleSaveTime(Date.now())}>
          <DialogContent>
            <DialogHeader>Missing information</DialogHeader>
            <DialogDescription className="text-neutral-800">
              To continue, fill in the missing information
            </DialogDescription>
            <form
              className="flex flex-col gap-3"
              onSubmit={handleSubmit((formData) => mutate(formData))}
            >
              {requiredConditions.phone && (
                <Controller
                  control={control}
                  name="phone"
                  render={({ field }) => (
                    <PhoneInputField
                      labelClassName="text-neutral-800"
                      label="Phone number"
                      variant="transparent"
                      {...field}
                      error={errors.phone}
                    />
                  )}
                />
              )}
              {requiredConditions.linkedinUrl && (
                <TextInputField
                  required
                  label="Your LinkedIn"
                  className="text-black"
                  type="url"
                  labelClassName="text-black"
                  placeholder="https://www.linkedin.com/in/super-user/"
                  variant="transparent"
                  error={errors.linkedinUrl?.message}
                  {...register('linkedinUrl')}
                />
              )}
              <Button
                data-cy="complete-data-submit"
                variant="tertiary"
                type="submit"
              >
                Submit
              </Button>
            </form>
          </DialogContent>
        </Dialog>
      </div>
    );
  return <>{children}</>;
};

export default BlockPageWithoutRequiredData;

const schema = object({
  phone: string().when('$phone', {
    is: true,
    then: (innerSchema) =>
      innerSchema.min(7).max(15).defined('This field is required'),
  }),
  linkedinUrl: string().when('$linkedinUrl', {
    is: true,
    then: (innerSchema) =>
      innerSchema
        .matches(
          /linkedin/,
          'Enter a valid LinkedIn URL starting with "https://www."'
        )
        .url()
        .defined('This field is required'),
  }),
});
